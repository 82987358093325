@import "src/constant/color";

.color_blue {
    color: $blue !important;
}
.color_white {
    color: $white !important;
}
.color_light_green {
    color: $light_green !important;
}
.color_middle_green {
    color: $middle_green !important;
}
.bg_green {
    background-color: $bg_green !important;
}
.bg_dark_green {
    background-color: $dark_green !important;
}
.mymodal {
    .modal-dialog{
        .modal_content {
            border-radius: 2.5rem;
            background-color: transparent;
        }
    }
}
.presale {
    background-color: $bg_dark;
    border-radius: 2rem;
    .sub_container {
        width: 100%;
        border-radius: 2rem;
        padding: 2rem;

        .form_control {
            border-width: 0;
            color: $white;
            padding: 1rem 1.5rem;
            border-radius: 2rem;
            &:focus {
                background-color: $dark_green;
                border-color: transparent;
                box-shadow: unset;
            }
        }
        .btn_outline_primary {
            border-color: $blue;
            padding: 0.5rem 2rem;
            font-weight: bold;
            &:hover {
                background-color: $blue;
                border-color: $blue;
                color: $bg_green !important;
            }
            &:focus {
                box-shadow: 0 0 0 0.25rem rgba(40, 186, 224, 0.2);
            }
        }
    }
}
